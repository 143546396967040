import React from 'react';
import './style.less';
import { Row, Col } from 'antd';

import title404 from "../../images/BREW-404_banner_text.png";

export default class PageTitle404 extends React.Component {
    render() {

        return (
            <div className="module_page_title">
                <Row className="big-row">
                    <Col xs={{span: 22}} md={{span: 18}} lg={{span: 12}}>
                        <h1><img src={title404} alt="404 - Page Not Found" /></h1>
                    </Col>
                </Row>
            </div>
        );
    }
}
