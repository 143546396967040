import React from 'react';
import './style.less';
import { Row, Col } from 'antd';
import {graphql, StaticQuery} from "gatsby";
import PostListItem from '../../components/PostListItem';
import DragScroll from "react-dragscroll";

class PostListing extends React.Component {
    render() {
        return (
            <div className="module_latest_post_listing">
                <div className="scrollable">
                    <DragScroll className="scroll_inner" height={540} width={100}>
                        <Row className="big-row">
                            {this.props.posts && this.props.posts.map(({node}) => {
                                return (
                                    <Col key={node.id} xs={{span: 8}}>
                                        <PostListItem post={node} />
                                    </Col>
                                )
                            })}
                        </Row>
                    </DragScroll>
                </div>
            </div>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql` query featuredBlogListQuery {
            allWordpressPost(limit: 3, sort: { order: DESC, fields: [date] }){
              edges {
                node {
                  id
                  slug
                  title
                  date(formatString: "YYYY-MM-DDTHH:MM:SS")
                  humanDate: date(formatString: "DD.MM.YYYY")
                  tags {
                    name
                    slug
                    id
                  }
                  acf {
                    reading_time
                    excerpt
                    image {
                      localFile {
                        childImageSharp {
                          fluid(maxHeight: 640, quality: 85) {
                            ... GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
           }
        `}
        render={(data) => (
            <PostListing posts={data.allWordpressPost.edges} />
        )}
    />
)
