import React from 'react';
import Wrapper from '../components/Wrapper';
import PostListing from '../modules/PostListing';
import PageTitle404 from '../modules/PageTitle404';
import {Col, Row, Button} from "antd";

export default class IndexPage extends React.Component {
    render(){
        return (
            <Wrapper
            title={"404 - Page not found"}>
                <PageTitle404 />
                <Row>
                    <Col xs={{span: 24}}>
                        <p>&nbsp;</p>
                        <h2>Oops... <br/>How'd you get here?</h2>
                        <p>It looks like the page you were looking for couldn’t be found, so either you’re getting creative with your URLs or one of our developers spilled a brew on the servers...</p>
                        <p>Either way, are you worried about the error pages hiding on your website?</p>
                        <p>Let’s chat about how a website audit might boost your rankings in Google search.</p>
                        <p>Or, you could find your way back to our homepage or have a browse through our latest blogs... Sometimes you need to get a little lost to discover something new. </p>
                        <p><Button type="primary" href="/" htmlType="button">Visit the homepage</Button> <Button type="primary" href="/contact-us/" htmlType="button">Contact us</Button></p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </Col>
                </Row>
                <PostListing />
            </Wrapper>
        );
    }
}
